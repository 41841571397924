import React, { useState, useEffect, useCallback } from "react";
import InformalForm from "./InformalForm";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import Button from "@mui/material/Button";
//import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { api } from "../../Api";

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": {
    borderColor: "green",
    borderWidth: 2,
  },
  "& input:invalid + fieldset": {
    borderColor: "red",
    borderWidth: 2,
  },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 5,
    padding: "4px !important",
  },
});
const mdTheme = createTheme();

const MainInformal = () => {
  const [logged, setLogged] = useState(false);

  const navigate = useNavigate();
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [subCategoryItem, setSubCategoryItem] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");
  const [subValue, setSubValue] = useState("");
  const [sponsor, setSponsor] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [buttonText, setButtonText] = useState("Validate");

  useEffect(() => {
    if (selectedItem === "" || selectedSubCategory === "" || subValue === "") {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [selectedItem, selectedSubCategory, subValue]);

  const handleSubCategoryItem = useCallback(async (e) => {
    const impt = e.target.value;
    setSelectedSubCategory(impt);
    try {
      const fetchItem = await Axios.post(`${api.baseURL}//Informal/${impt}`);
      const subItem = fetchItem.data.data;
      setSubCategoryItem(subItem);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getValidation = async (e) => {
    e.preventDefault();
    setButtonText("Validating...");
    const params = JSON.stringify({
      submission_id: subValue,
      subcategoryiid: selectedItem,
    });

    const link = `${api.baseURL}/validate/record`;

    try {
      Axios.post(link, params, {
        headers: {
          "content-type": "application/json",
        },
      }).then(function (response) {
        if (
          response.status === 200 &&
          response.data.status === true &&
          response.data.data.status === "Merged"
        ) {
          toast.success("Enrolled already!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            hideProgressBar: true,
          });
          setLogged(false);
          setButtonText("Validate");
          setIsDisabled(true);
          setSelectedItem("");
          setSelectedSubCategory("");
          setSubValue("");
        } else if (
          response.status === 200 &&
          response.data.status === true &&
          response.data.data.status === "Not_Merged"
        ) {
          setLogged(true);
          setButtonText("Validate");
          toast.success("Validation Successfull!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 4000,
            hideProgressBar: true,
          });
          setSponsor(response.data.data);
          setIsDisabled(true);
        } else if (response.status === 200 && response.data.status === false) {
          setLogged(false);
          setIsDisabled(true);
          setSelectedItem("");
          setButtonText("Validate");
          setSelectedSubCategory("");
          setSubValue("");
          toast.error(
            "No record found. Please Contact PLASCHEMA on 0700-PLATEAU",
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 5000,
              hideProgressBar: true,
            }
          );
        } else {
          setLogged(false);
          setButtonText("Validate");
          setIsDisabled(true);
          setSelectedItem("");
          setSelectedSubCategory("");
          setSubValue("");
          toast.error("Please Contact PLASCHEMA on 0700-PLATEAU", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            hideProgressBar: true,
          });
        }
      });
    } catch (err) {
      console.log(err);
      setButtonText("Validate");
    }
  };
  return (
    <div>
      <ToastContainer />
      {!logged ? (
        <ThemeProvider theme={mdTheme}>
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[200]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "auto",
              overflow: "hidden",
            }}
          >
            <Container>
              <p className="font-spec font-semibold my-10 text-md lg:text-3xl tracking-wider">
                Enrollment - Informal Sector Enrollment
              </p>
              <div
                onClick={() => navigate(-1)}
                className="back"
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  cursor: "pointer",
                }}
              >
                <ArrowBackIosIcon fontSize={"small"} />
                <span>Back</span>
              </div>
              <Paper className="paper1">
                <form onSubmit={getValidation}>
                  <div className="textfield">
                    <TextField
                      className="box"
                      select
                      required
                      SelectProps={{
                        native: true,
                      }}
                      sx={{
                        width: "23ch",
                      }}
                      onChange={handleSubCategoryItem}
                      value={selectedSubCategory}
                    >
                      <option value="">Select Subcategory</option>
                      <option value="Association" key="0">
                        Association
                      </option>
                      <option value="Sponsored" key="1">
                        Sponsored
                      </option>
                    </TextField>
                    <TextField
                      className="box"
                      select
                      required
                      SelectProps={{
                        native: true,
                      }}
                      sx={{
                        width: "23ch",
                      }}
                      onChange={(e) => setSelectedItem(e.target.value)}
                      value={selectedItem}
                    >
                      <option value="">Select Subcategory item</option>
                      {subCategoryItem?.map((opt) => (
                        <option key={opt.name} value={opt.id}>
                          {opt.name}
                        </option>
                      ))}
                    </TextField>

                    <ValidationTextField
                      className="box"
                      label="Submission ID"
                      name="id"
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                      required
                      variant="outlined"
                      placeholder="Enter enrollee submission id"
                      onChange={(e) => setSubValue(e.target.value)}
                      value={subValue}
                      id="validation-outlined-input"
                    />
                  </div>
                  <div className="btn">
                    <div className="app-btn1">
                      <Button
                        className="next"
                        type="submit"
                        disabled={isDisabled}
                        variant="contained"
                        color="success"
                      >
                        {buttonText}
                        <CheckCircleOutlineOutlinedIcon
                          style={{
                            fontSize: "20px",
                            marginBottom: "4px",
                            marginLeft: "4px",
                          }}
                        />
                      </Button>
                    </div>
                    <div className="app-btn1">
                      <Button
                        className="next"
                        type="button"
                        onClick={() => {
                          navigate("/general");
                        }}
                        variant="contained"
                        color="success"
                      >
                        General
                      </Button>
                    </div>
                  </div>
                </form>
              </Paper>
            </Container>
          </Box>
        </ThemeProvider>
      ) : (
        <>
          <InformalForm sponsor={sponsor} />
        </>
      )}
    </div>
  );
};

export default MainInformal;
