import React, { useState, useEffect, useCallback } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Button from "@mui/material/Button";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import Preview from "./PreviewGeneral";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { api } from "../../Api";
import {
	category,
	genotype,
	idtype,
	emergency,
	marital,
	blood,
	occupation,
} from "../formData";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

const myDate = new Date()
	.toISOString()
	.replace("-", "/")
	.split("T")[0]
	.replace("-", "/");
const ValidationTextField = styled(TextField)({
	"& input:valid + fieldset": {
		borderColor: "green",
		borderWidth: 2,
	},
	"& input:invalid + fieldset": {
		borderColor: "red",
		borderWidth: 2,
	},
	"& input:valid:focus + fieldset": {
		borderLeftWidth: 5,
		padding: "4px !important",
	},
});
const mdTheme = createTheme();

const MAX_STEPS = 3;

function GeneralForm({ details }) {
	const navigate = useNavigate();
	const [formStep, setFormStep] = useState(0);
	const [buttonText, setButtonText] = useState("Submit");

	const [myLga, setMyLga] = useState([]);
	const [ward, setWard] = useState([]);
	const [facility, setFacility] = useState([]);
	const [selectedLga, setSelectedLga] = useState("");
	const [selectedWard, setSelectedWard] = useState("");
	const [selectedFacility, setSelectedFacility] = useState("");
	const [homeAddress, setHomeAddress] = useState("");
	const [phone, setPhone] = useState("");
	const [genotypeValue, setGenotype] = useState("");
	const [settlement, setSettlement] = useState("");
	const [idType, setIdType] = useState("");
	const [bloodGroup, setBloodgroup] = useState("");
	const [categoryValue, setCategory] = useState("");
	const [relationship, setRelationship] = useState("");
	const [email, setEmail] = useState("");
	const [maritalStatus, setMarital] = useState("");
	const [nok, setNok] = useState("");
	const [nokPhone, setNokPhone] = useState("");
	const [passport, setPassport] = useState(null);
	const [idPhoto, setIdPhoto] = useState(null);
	const [nin, setNin] = useState("");
	const [name, setName] = useState("");
	const [emergAddress, setEmergAddress] = useState("");
	const [nationality, setNationality] = useState("");
	const [occupationId, setOccupation] = useState("");
	const [enrollmentId, setEnrollmentId] = useState("");
	const [subCategoryItem, setSubCategoryItem] = useState([]);
	const [selectedSubItem, setSelectedSubItem] = useState("");
	const [gender, setGender] = useState("");
	const [dob, setDob] = useState("");
	const [hmo, setHmo] = useState([]);
	const [isDisabled, setIsDisabled] = useState(false);

	useEffect(() => {
		if (
			!selectedLga ||
			!selectedWard ||
			!selectedFacility ||
			!homeAddress ||
			!phone ||
			!genotypeValue ||
			!settlement ||
			!idType ||
			!bloodGroup ||
			!categoryValue ||
			!relationship ||
			!email ||
			!maritalStatus ||
			!nok ||
			!dob ||
			!gender ||
			!nokPhone ||
			passport === null ||
			idPhoto === null ||
			!nin ||
			!emergAddress ||
			!nationality ||
			!occupationId
		) {
			setIsDisabled(true);
		} else {
			setIsDisabled(false);
		}
	}, [
		selectedLga,
		selectedWard,
		selectedFacility,
		homeAddress,
		phone,
		genotypeValue,
		settlement,
		idType,
		bloodGroup,
		categoryValue,
		relationship,
		email,
		maritalStatus,
		nok,
		dob,
		gender,
		nokPhone,
		passport,
		idPhoto,
		nin,
		emergAddress,
		nationality,
		occupationId,
	]);

	const getLocal = async () => {
		try {
			const fetchLocal = await Axios.get(`${api.baseURL}/lga/enrollment`);
			setMyLga(fetchLocal.data.data);
		} catch (err) {
			console.log(err);
		}
	};

	const handleWard = (e) => {
		const result = e.target.value;
		setSelectedLga(result);
		Axios.get(`${api.baseURL}/${result}/wards/enrollment`).then((response) => {
			setWard(response.data.data);
		});
	};

	const handleFac = useCallback(
		async (e) => {
			const impt = e.target.value;
			setSelectedWard(impt);
			try {
				const fetchItem = await Axios.post(
					`${api.baseURL}/facility/${selectedLga}/${impt}`,
				);
				const subItem = fetchItem.data.data;
				setFacility(subItem);
			} catch (err) {
				console.log(err);
			}
		},
		[selectedLga],
	);

	const getSubCategoryItem = async () => {
		try {
			const fetchItem = await Axios.post(`${api.baseURL}//Informal/General`);
			const subItem = fetchItem.data.data;
			setSubCategoryItem(subItem);
		} catch (err) {
			console.log(err);
		}
	};

	const getHmo = useCallback(async () => {
		try {
			const fetchHmo = await Axios.post(`${api.baseURL}/hmo`);
			const resultHmo = fetchHmo.data.data;
			setHmo(resultHmo[4]);
		} catch (err) {
			console.log(err);
		}
	}, []);

	useEffect(() => {
		getHmo();
	}, [getHmo]);

	useEffect(() => {
		getLocal();
	}, []);

	useEffect(() => {
		getSubCategoryItem();
	}, []);

	const completeFormStep = () => {
		setFormStep((cur) => cur + 1);
	};

	const renderButton = () => {
		if (formStep > 2) {
			return (
				<Button
					type="button"
					className="next"
					variant="outlined"
					onClick={(e) => {
						e.preventDefault();
						navigate("/");
					}}
				>
					Go Back Home
				</Button>
			);
		} else if (formStep === 2) {
			return (
				<Button
					className="next"
					type="submit"
					disabled={isDisabled}
					variant="contained"
					color="success"
				>
					{buttonText}
					<CheckCircleOutlineOutlinedIcon />
				</Button>
			);
		} else {
			return (
				<Button
					className="next"
					onClick={completeFormStep}
					type="button"
					variant="contained"
					color="success"
				>
					Next Step
					<NavigateNextRoundedIcon fontSize={"medium"} />
				</Button>
			);
		}
	};

	const submitForm = (e) => {
		e.preventDefault();
		setButtonText("Submitting Form...");

		const newFac = JSON.parse(selectedFacility);
		const newItem = JSON.parse(selectedSubItem);

		var enrollData = new FormData();

		enrollData.append("passport", passport);
		enrollData.append("id_photo", idPhoto);
		enrollData.append("genotype", genotypeValue);
		enrollData.append("nok_phone", nokPhone);
		enrollData.append("settlement_type", settlement);
		enrollData.append("id_type", idType);
		enrollData.append("lga", selectedLga);
		enrollData.append("phone_number", phone);
		enrollData.append("next_of_kin", nok);
		enrollData.append("home_address", homeAddress);
		enrollData.append("blood_group", bloodGroup);
		enrollData.append("submission_id", details?.paymentref);
		enrollData.append("relationship_with_emergency_co", relationship);
		enrollData.append("ward", selectedWard);
		enrollData.append("email", email);
		enrollData.append("nin_number", nin);
		enrollData.append("gender", gender);
		enrollData.append("facility_id", newFac.id);
		enrollData.append("facility_name", newFac.name);
		enrollData.append("hmo_id", hmo.id);
		enrollData.append("hmo_name", hmo.name);
		enrollData.append("emergency_contact_address", emergAddress);
		enrollData.append("category", categoryValue);
		enrollData.append("marital_status", maritalStatus);
		enrollData.append("nationality", nationality);
		enrollData.append("date_of_birth", dob);
		enrollData.append("subcategoryiid", newItem.id);
		enrollData.append("subcategoryitem", newItem.name);
		enrollData.append("name", name);
		enrollData.append("occupation", occupationId);
		enrollData.append("duration", details?.duration);
		enrollData.append("enrolled_by", sessionStorage.getItem("email_user"));

		Axios({
			method: "POST",
			data: enrollData,
			url: `${api.baseURL}/enrollment/details`,
			headers: { "Content-Type": "multipart/form-data" },
		})
			.then((response) => {
				setEnrollmentId(response.data.enrollment_id);
				setButtonText("Submit");
				toast.success("Form Submitted Successfully!", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 8000,
					hideProgressBar: true,
				});
				completeFormStep();
			})
			.catch(function (error) {
				console.log(error);
				setButtonText("Submit");
				toast.error("Form submit error. Try again!", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 8000,
					hideProgressBar: true,
				});
			});
	};

	const goToPreviousStep = () => {
		setFormStep((cur) => cur - 1);
	};

	return (
		<ThemeProvider theme={mdTheme}>
			<ToastContainer />
			<Box
				component="main"
				sx={{
					backgroundColor: (theme) =>
						theme.palette.mode === "light"
							? theme.palette.grey[200]
							: theme.palette.grey[900],
					flexGrow: 1,
					height: "auto",
					overflow: "hidden",
				}}
			>
				<Toolbar />

				<Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
					<p className="font-spec font-semibold my-10 text-lg lg:text-3xl tracking-wider">
						Informal Sector - General Beneficiaries Enrollment Form
					</p>

					{formStep < MAX_STEPS && (
						<div>
							<div className="stepper">
								<Stepper activeStep={formStep}>
									<Step>
										<StepLabel>Nominal Roll</StepLabel>
									</Step>
									<Step>
										<StepLabel>Other Information</StepLabel>
									</Step>
									<Step>
										<StepLabel>Submission</StepLabel>
									</Step>
								</Stepper>
							</div>
							<div className="steps">
								{formStep > 0 && (
									<ArrowBackIosIcon
										onClick={goToPreviousStep}
										fontSize={"10px"}
									/>
								)}

								<p>
									Step {formStep + 1} of {MAX_STEPS}
								</p>
							</div>
						</div>
					)}
					<form onSubmit={submitForm}>
						<Paper className="paper">
							{formStep >= 0 && (
								<div
									style={
										formStep === 0 ? { display: "block" } : { display: "none" }
									}
								>
									<div className="textfield">
										<TextField
											className="box"
											select
											required
											SelectProps={{
												native: true,
											}}
											sx={{
												width: "23ch",
											}}
											value={selectedSubItem}
											onChange={(e) => setSelectedSubItem(e.target.value)}
										>
											<option value="">Select Subcategory item</option>
											{subCategoryItem?.map((opt, name) => (
												<option
													key={`${opt}-${name}`}
													value={JSON.stringify(opt)}
												>
													{opt.name}
												</option>
											))}
										</TextField>

										<ValidationTextField
											className="box"
											label="Submission ID"
											InputProps={{
												readOnly: true,
											}}
											name="submission_id"
											variant="outlined"
											placeholder="Enter enrollee PSN"
											id="validation-outlined-input"
											value={details?.paymentref || "No data"}
										/>

										<ValidationTextField
											className="box"
											label="Name"
											name="name"
											required
											type="text"
											variant="outlined"
											id="validation-outlined-input"
											sx={{
												width: "23ch",
											}}
											value={name}
											onChange={(e) => setName(e.target.value)}
										/>
									</div>
									<div className="textfield">
										<TextField
											className="box"
											select
											name="gender"
											required
											SelectProps={{
												native: true,
											}}
											sx={{
												width: "23ch",
											}}
											value={gender}
											onChange={(e) => setGender(e.target.value)}
										>
											<option value="">Select Gender</option>
											<option value="male">Male</option>
											<option value="female">Female</option>
										</TextField>

										<ValidationTextField
											className="box"
											label="Date of Birth"
											required
											type="date"
											name="date_of_birth"
											variant="outlined"
											value={dob}
											onChange={(e) => setDob(e.target.value)}
										/>

										<TextField
											className="box"
											InputProps={{
												readOnly: true,
											}}
											name="hmo"
											SelectProps={{
												native: true,
											}}
											sx={{
												width: "23ch",
											}}
											value={hmo.name}
										/>
									</div>

									<div className="textfield">
										<TextField
											className="box"
											select
											label="Occupation"
											name="Occupation"
											required
											SelectProps={{
												native: true,
											}}
											sx={{
												width: "23ch",
											}}
											value={occupationId}
											onChange={(e) => setOccupation(e.target.value)}
										>
											<option>Select Occupation</option>
											{occupation.map((job) => (
												<option value={job.value} key={job.key}>
													{job.value}
												</option>
											))}
										</TextField>

										<ValidationTextField
											className="box"
											label="Phone Number"
											type="text"
											required
											name="phone_number"
											inputProps={{
												inputMode: "numeric",
												pattern: "[0-9]*",
												maxLength: 11,
											}}
											variant="outlined"
											placeholder="Enter your phone number"
											id="validation-outlined-input"
											value={phone}
											onChange={(e) => setPhone(e.target.value)}
										/>

										<TextField
											className="box"
											select
											label="Marital Status"
											name="marital_status"
											required
											SelectProps={{
												native: true,
											}}
											sx={{
												width: "23ch",
											}}
											value={maritalStatus}
											onChange={(e) => setMarital(e.target.value)}
										>
											<option>Please Select</option>
											{marital.map((i) => (
												<option value={i.value} key={i.key}>
													{i.value}
												</option>
											))}
										</TextField>
									</div>
								</div>
							)}
							{formStep >= 1 && (
								<div
									style={
										formStep === 1 ? { display: "block" } : { display: "none" }
									}
								>
									<div className="textfield">
										<TextField
											className="box"
											select
											label="Blood Group"
											name="blood_group"
											required
											SelectProps={{
												native: true,
											}}
											sx={{
												width: "23ch",
											}}
											value={bloodGroup}
											onChange={(e) => setBloodgroup(e.target.value)}
										>
											<option>Select your Blood Group</option>
											{blood.map((item) => (
												<option value={item.value} key={item.key}>
													{item.value}
												</option>
											))}
										</TextField>
										<TextField
											className="box"
											select
											label="Genotype"
											name="genotype"
											required
											SelectProps={{
												native: true,
											}}
											sx={{
												width: "23ch",
											}}
											value={genotypeValue}
											onChange={(e) => setGenotype(e.target.value)}
										>
											<option>Select your Genotype</option>
											{genotype.map((g) => (
												<option value={g.value} key={g.key}>
													{g.value}
												</option>
											))}
										</TextField>
										<TextField
											className="box"
											select
											label="Category"
											name="category"
											required
											SelectProps={{
												native: true,
											}}
											sx={{
												width: "23ch",
											}}
											value={categoryValue}
											onChange={(e) => setCategory(e.target.value)}
										>
											<option>Select your Category</option>
											{category.map((c) => (
												<option value={c.value} key={c.key}>
													{c.value}
												</option>
											))}
										</TextField>
									</div>

									<div className="textfield">
										<ValidationTextField
											className="box"
											label="Email Address"
											type="email"
											required
											name="email"
											variant="outlined"
											placeholder="Enter your email address"
											id="validation-outlined-input"
											value={email}
											onChange={(e) => setEmail(e.target.value)}
										/>

										<TextField
											className="box"
											select
											label="Nationality"
											name="nationality"
											required
											SelectProps={{
												native: true,
											}}
											sx={{
												width: "23ch",
											}}
											value={nationality}
											onChange={(e) => setNationality(e.target.value)}
										>
											<option>Select your Nationality</option>
											<option value="nigerian">Nigerian</option>
											<option value="non nigerian">Non-Nigerian</option>
										</TextField>

										<ValidationTextField
											className="box"
											label="Emergency Contact Address"
											required
											name="emergency_contact_address"
											variant="outlined"
											placeholder="Enter Emergency Contact Address"
											id="validation-outlined-input"
											value={emergAddress}
											onChange={(e) => setEmergAddress(e.target.value)}
										/>
									</div>

									<div className="textfield">
										<TextField
											className="box"
											select
											label="Relationship With Emergency Contact"
											name="relationship_with_emergency_co"
											required
											SelectProps={{
												native: true,
											}}
											sx={{
												width: "23ch",
											}}
											value={relationship}
											onChange={(e) => setRelationship(e.target.value)}
										>
											<option>Please Select</option>
											{emergency.map((r) => (
												<option value={r.value} key={r.key}>
													{r.value}
												</option>
											))}
										</TextField>
										<TextField
											className="box"
											select
											label="ID Type"
											name="id_type"
											required
											SelectProps={{
												native: true,
											}}
											sx={{
												width: "23ch",
											}}
											value={idType}
											onChange={(e) => setIdType(e.target.value)}
										>
											<option>Please Select your ID type</option>
											{idtype.map((k) => (
												<option value={k.value} key={k.key}>
													{k.value}
												</option>
											))}
										</TextField>

										<ValidationTextField
											className="box"
											required
											label="Home Address"
											name="home_address"
											variant="outlined"
											placeholder="Enter Home Address"
											value={homeAddress}
											onChange={(e) => setHomeAddress(e.target.value)}
										/>
									</div>
								</div>
							)}

							{formStep >= 2 && (
								<div
									style={
										formStep === 2 ? { display: "block" } : { display: "none" }
									}
								>
									<div className="textfield">
										<TextField
											className="box"
											required
											name="id_photo"
											type="file"
											accept="image/*"
											label="ID Photo"
											id="contained-button-file"
											multiple
											sx={{
												width: "23ch",
											}}
											onChange={(e) => setIdPhoto(e.target.files[0])}
										/>

										<TextField
											required
											className="box"
											name="passport"
											accept="image/*"
											id="contained-button-file"
											multiple
											label="Passport"
											type="file"
											sx={{
												width: "23ch",
											}}
											onChange={(e) => setPassport(e.target.files[0])}
										/>

										<ValidationTextField
											className="box"
											label="NIN"
											required
											type="text"
											name="nin_number"
											variant="outlined"
											placeholder="Enter NIN number"
											value={nin}
											onChange={(e) => setNin(e.target.value)}
										/>
									</div>
									<div className="textfield">
										<TextField
											className="box"
											name="lga"
											select
											required
											value={selectedLga}
											onChange={handleWard}
											SelectProps={{
												native: true,
											}}
											sx={{
												width: "23ch",
											}}
										>
											<option value="">Select LGA </option>
											{myLga?.map((curr) => (
												<option key={curr}>{curr}</option>
											))}
										</TextField>
										<TextField
											className="box"
											name="ward"
											select
											value={selectedWard}
											onChange={handleFac}
											SelectProps={{
												native: true,
											}}
											sx={{
												width: "23ch",
											}}
										>
											<option value="">Select Ward</option>
											{ward?.map((dep) => (
												<option key={dep}>{dep}</option>
											))}
										</TextField>

										<TextField
											className="box"
											name="facility_name"
											select
											required
											value={selectedFacility}
											onChange={(e) => setSelectedFacility(e.target.value)}
											SelectProps={{
												native: true,
											}}
											sx={{
												width: "23ch",
											}}
										>
											<option value="">Select Facility</option>
											{facility?.map((tul) => (
												<option key={tul.id} value={JSON.stringify(tul)}>
													{tul.name}
												</option>
											))}
										</TextField>
									</div>
									<div className="textfield">
										<ValidationTextField
											className="box"
											label="Next of Kin"
											required
											name="next_of_kin"
											variant="outlined"
											placeholder="Enter Next of Kin"
											value={nok}
											onChange={(e) => setNok(e.target.value)}
										/>
										<ValidationTextField
											className="box"
											label="Next of Kin Phone Number"
											name="nok_phone"
											required
											variant="outlined"
											placeholder="Enter Next of Kin Phone Number"
											id="validation-outlined-input"
											inputProps={{
												inputMode: "numeric",
												pattern: "[0-9]*",
												maxLength: 11,
											}}
											value={nokPhone}
											onChange={(e) => setNokPhone(e.target.value)}
										/>
										<TextField
											className="box"
											select
											name="settlement_type"
											label="Settlement Type"
											required
											SelectProps={{
												native: true,
											}}
											sx={{
												width: "23ch",
											}}
											value={settlement}
											onChange={(e) => setSettlement(e.target.value)}
										>
											<option>Select Settlement Type</option>
											<option value="rural">Rural</option>
											<option value="urban">Urban</option>
											<option value="idp camp">IDP Camp</option>
										</TextField>
									</div>
								</div>
							)}
							{formStep === 3 && (
								<Preview
									name={name}
									gender={gender}
									passport={passport}
									bloodGroup={bloodGroup}
									nokPhone={nokPhone}
									selectedFacility={selectedFacility}
									myDate={myDate}
									enrollmentId={enrollmentId}
								/>
							)}
						</Paper>
						<div className="btn">{renderButton()}</div>
					</form>
				</Container>
			</Box>
		</ThemeProvider>
	);
}

export default GeneralForm;
