const Users = [
	{
		email: "staff@plaschema.com",
		password: "staff@idl",
		name: "IDL Staff",
	},
	{
		email: "bigasamaben@yahoo.com",
		password: "asama234",
		name: "Asama Benjamin",
	},
	{
		email: "cgurumyen@gmail.com",
		password: "guru234",
		name: "Cornelius Gurumyen",
	},
	{
		email: "zee4buks@gmail.com",
		password: "zee234",
		name: "Zahradeen Usman",
	},
	{
		email: "akaninyenejames405@gmail.com",
		password: "akan234",
		name: "James Akaniyene",
	},
	{
		email: "awinspeter@gmail.com",
		password: "peter234",
		name: "Bwakat Peter",
	},

	{
		email: "shepyentongjap@gmail.com",
		password: "tong234",
		name: "Shepyen Tongjap",
	},
	{
		email: "kaatlongplacida8.kp@gmail.com",
		password: "kwaps234",
		name: "Kwaps Placida Ka'atlong",
	},
	{
		email: "joshman3life@gmail.com",
		password: "josh234",
		name: "Nanmwo Dagaen Joshua",
	},
	{
		email: "wuyepabdul@gmail.com",
		password: "wuyep234",
		name: "Wuyep Abdul Datti",
	},
	{
		email: "nathansani032@gmail.com",
		password: "sani234",
		name: "Sani Godiya Madaki",
	},
	{
		email: "israeldapakdapak@gmail.com",
		password: "dapak234",
		name: "Dapak Isreal Dapak",
	},
	{
		email: "cecilialanna08@gmail.com",
		password: "cecilia234",
		name: "Panan Cecilia Gongden",
	},
];
export default Users;
