import { CubeGrid } from "better-react-spinkit";

function Spinner() {
    return (
        <center style={{display: "grid", placeItems: "center", height: "100vh" }}>
        <div>
        

         <CubeGrid color="#1eae98" size={70} />
        
         
        </div>
      </center>
    )
}

export default Spinner;
