import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from "react-router-dom";
import { positions, Provider, transitions } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

const options = {
  timeout: 5000,
  position: positions.TOP_CENTER,
  transition: transitions.FADE,
};

const root = ReactDOM.createRoot(document.getElementById('root'));



root.render(
  <React.StrictMode>
<Provider template={AlertTemplate} {...options}>
  <Router>
    <App />
    </Router>
    </Provider>
  </React.StrictMode>
);

