import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Banner from "../../src/images/Banner.jpeg";

const Home = () => {
	const navigate = useNavigate();
	const userName = sessionStorage.getItem("email_user");
	const [isDisabled, setIsDisabled] = useState(true);

	useEffect(() => {
		if (
			userName === "yilkatnijin@gmail.com" ||
			userName === "akaninyenejames405@gmail.com" ||
			userName === "shepyentongjap@gmail.com"
		) {
			setIsDisabled(false);
		} else {
			setIsDisabled(true);
		}
	}, [userName]);

	return (
		<div className="h-[65vh] bg-[#69e774] bg-plaschema relative bg-center bg-cover bg-no-repeat">
			<div className="pt-[1rem]">
				<div className="mb-7">
					<img
						className="my-1 mx-auto rounded-full transition-transform hover:scale-105 duration-500 ease-in-out"
						src={Banner}
						alt=""
						height={200}
						width={250}
					/>
				</div>
				<div className="font-cant font-bold text-xl">
					<h3 className="mb-4">
						{" "}
						<span className="text-blue-800 font-bold">Click</span> on your{" "}
						<span className="text-blue-800 font-bold">Category</span> below to
						proceed...
					</h3>
					<div>
						<button
							onClick={() => {
								navigate("/formalsector");
							}}
							className="mr-2 bg-black hover:bg-gray-500 text-white font-semibold hover:text-black py-2 px-4 border border-black-500 hover:border-transparent rounded"
						>
							Formal
						</button>

						<button
							onClick={() => {
								navigate("/informalsector");
							}}
							className="mr-2 bg-black hover:bg-gray-500 text-white font-semibold hover:text-black py-2 px-4 border border-black-500 hover:border-transparent rounded"
						>
							Informal
						</button>

						<button
							disabled={isDisabled}
							onClick={() => {
								navigate("/equityform");
							}}
							className={
								isDisabled
									? "mr-2 bg-black text-white font-semibold hover:text-white py-2 px-4 border border-black-500 hover:border-transparent rounded"
									: "mr-2 bg-black hover:bg-gray-500 text-white font-semibold hover:text-black py-2 px-4 border border-black-500 hover:border-transparent rounded"
							}
						>
							Equity
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
