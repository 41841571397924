import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

export default function StickyHeadTable({ searchEnroll }) {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden", margin: "auto" }}>
      <h4 style={{ textAlign: "center"}}>
        Search results: {searchEnroll ? searchEnroll?.length : "0"}
      </h4>
      <TableContainer sx={{ maxHeight: 400 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: "black", fontWeight: "600" }}>
                Submission ID
              </TableCell>
              <TableCell sx={{ color: "black", fontWeight: "600" }}>
                Name
              </TableCell>

              <TableCell sx={{ color: "black", fontWeight: "600" }}>
                Status
              </TableCell>
              <TableCell sx={{ color: "black", fontWeight: "600" }}>
                Phone Number
              </TableCell>
              <TableCell sx={{ color: "black", fontWeight: "600" }}>
                Subcategory Item
              </TableCell>
            </TableRow>
          </TableHead>

          {searchEnroll?.map((item, index) => (
            <TableBody key={index}>
              <TableRow>
                <TableCell>{item?.submission_id}</TableCell>
                <TableCell sx={{ color: "blue", cursor: "pointer" }}>
                  {item?.name}
                </TableCell>
                <TableCell
                  style={
                    item?.status === "Merged"
                      ? { color: "green" }
                      : { color: "red" }
                  }
                >
                  {item.status}
                </TableCell>
                <TableCell>{item?.phone_number}</TableCell>
                <TableCell>{item.subcategoryitem?.name}</TableCell>
              </TableRow>
            </TableBody>
          ))}
        </Table>
      </TableContainer>
    </Paper>
  );
}
