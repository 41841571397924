import React, { useState, useEffect } from "react";
import "./App.css";
import Loading from "./Spinner";
import FormalSector from "../src/components/FormalSector/Main";
import EquitySector from "../src/components/Equity/MainEquity";
import EquityForm from "../src/components/Equity/EquityForm";
import InformalSector from "../src/components/InformalSector/MainInformal";
import General from "../src/components/InformalSector/General";
import Dependent from "../src/components/Dependents/MainDependent";
import { Route, Routes, useNavigate } from "react-router-dom";
import Home from "../src/components/Home";
import Header from "../src/components/Header";
import Typography from "@mui/material/Typography";
import Login from "./components/Login";
import Search from "./components/Search";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Users from "./UserLogin";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      Designed for Plateau State Contributory Health Care Management Agency by{" "}
      <a className="link" href="https://instantdeposit.africa">
        Instant Deposit Ltd.
      </a>{" "}
      All Rights Reserved.
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function App() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const login = (details) => {
    const validUser = Users.find((user) => {
      const email = details.email;
      const password = details.password;
      return user.email === email && user.password === password;
    });

   

    if (validUser) {
      sessionStorage.setItem("email_user", details.email);
      toast.success(` Welcome ${validUser.name}`, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 4000,
        hideProgressBar: false,
      });
      navigate("/");
    } else {
      toast.error("🤦‍♂️ Invalid email or password! Please check your entries.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
        hideProgressBar: false,
      });
    }
  };

  // localStorage.setItem("logged", JSON.stringify(true));

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <div className="App">
      <ToastContainer />
      {loading ? (
        <Loading />
      ) : (
        <>
          {!sessionStorage.getItem("email_user") ? (
            <Login login={login} />
          ) : (
            <>
              <Header />
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/formalsector" element={<FormalSector />} />
                <Route exact path="/equity" element={<EquitySector />} />
                <Route exact path="/equityform" element={<EquityForm />} />
                <Route exact path="/dependents" element={<Dependent />} />
                <Route exact path="/general" element={<General />} />
                <Route exact path="/search" element={<Search />} />

                <Route
                  exact
                  path="/informalsector"
                  element={<InformalSector />}
                />
              </Routes>

              <Copyright sx={{ pt: 7 }} />
            </>
          )}
        </>
      )}
    </div>
  );
}

export default App;
