import React from "react";
import Button from "@mui/material/Button";
import { jsPDF } from "jspdf";
//import html2canvas from 'html2canvas';

function PreviewEquity({
  name,
  gender,
  passport,
  bloodGroup,
  nokPhone,
  selectedFacility,
  myDate,
  enrollmentId,
}) {
  const handleClick = async (e) => {
    e.preventDefault();

    var doc = new jsPDF("p", "pt", "a4");
    doc.html(document.querySelector("#content"), {
      callback: function (pdf) {
        pdf.save(`${name}.pdf`);
      },
    });
  };

  return (
    <div>
      <h2 className="text-xl md:text-2xl font-pop font-semibold text-green-600 py-5">
        New Enrollment
      </h2>

      <div
        id="content"
        className="w-[100%] lg:grid lg:place-items-center  mx-auto   lg:bg-gray-100 lg:rounded-md lg:border-2"
      >
        <img
          src={URL.createObjectURL(passport)}
          alt={"passport"}
          className="w-[200px] h-[200px] rounded-full ml-10 mt-5 mb-[8rem]"
        />

        <div
          align="left"
          className="mt-[4rem] ml-4 lg:mt-[-13rem] leading-[50px]"
        >
          <p className="font-cant font-semibold">
            Name:{" "}
            <span className="whitespace-normal text-green-500 pl-3">
              {name}
            </span>
          </p>
          <p className="font-cant font-semibold">
            Gender:{" "}
            <span className="whitespace-normal text-green-500 pl-3">
              {gender}
            </span>
          </p>
          <p className="font-cant font-semibold">
            Blood Group:{" "}
            <span className="whitespace-normal text-green-500 pl-3">
              {bloodGroup}
            </span>
          </p>
          <p className="font-cant font-semibold">
            Next of Kin contact:{" "}
            <span className="whitespace-normal text-green-500 pl-3">
              {nokPhone}
            </span>
          </p>
          <p className="font-cant font-semibold">
            Facility:{" "}
            <span className="whitespace-normal text-green-500 pl-3">
              {" "}
              {JSON.parse(selectedFacility)?.name}
            </span>
          </p>
          <p className="font-cant font-semibold">
            Date of Enrollment:{" "}
            <span className="whitespace-normal text-green-500 pl-3">
              {myDate}
            </span>
          </p>
          <p className="font-cant font-semibold">
            Enrollment ID:{" "}
            <span className="whitespace-normal text-green-500 pl-3">
              {enrollmentId}
            </span>
          </p>
        </div>
      </div>
      <Button
        type="button"
        variant="outlined"
        onClick={handleClick}
        sx={{ width: "20ch", margin: "50px" }}
      >
        Download
      </Button>
    </div>
  );
}

export default PreviewEquity;
