import React, { useState, useEffect } from "react";
import GeneralForm from "./GeneralForm";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { api } from "../../Api";

const mdTheme = createTheme();

const General = () => {
  const [logged, setLogged] = useState(false);
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState("");

  const [details, setDetails] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [buttonText, setButtonText] = useState("Validate");

  useEffect(() => {
    if (selectedItem.length === 13) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [selectedItem]);

  const getGeneralValidation = async (e) => {
    e.preventDefault();
    setButtonText("Validating...");
    const params = JSON.stringify({
      paymentref: selectedItem,
    });

    const link = `${api.baseURL}/confirm/payment`;

    try {
      Axios.post(link, params, {
        headers: {
          "content-type": "application/json",
        },
      }).then(function (response) {
        if (
          response.status === 200 &&
          response.data.status === true &&
          response.data.data.status === "Merged"
        ) {
          toast.success("Enrolled already!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            hideProgressBar: true,
          });
          setLogged(false);
          setIsDisabled(true);
          setSelectedItem("");
          setButtonText("Validate");
        } else if (
          response.status === 200 &&
          response.data.status === true &&
          response.data.data.status === "Not_Merged"
        ) {
          toast.success("Validation Successfull!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 4000,
            hideProgressBar: true,
          });
          setLogged(true);
          setButtonText("Validate");
          setIsDisabled(true);
          setDetails(response.data.data);
        } else if (response.status === 200 && response.data.status === false) {
          setLogged(false);
          setIsDisabled(true);
          setSelectedItem("");
          setButtonText("Validate");
          toast.error(
            "No record found. Please Contact PLASCHEMA On 0700-PLATEAU",
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 5000,
              hideProgressBar: true,
            }
          );
        } else {
          setLogged(false);
          setIsDisabled(true);
          setSelectedItem("");
          setButtonText("Validate");
          toast.error("Please Contact PLASCHEMA On 0700-PLATEAU", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            hideProgressBar: true,
          });
        }
      });
    } catch (err) {
      console.log(err);
      setButtonText("Validate");
    }
  };

  return (
    <div>
      <ToastContainer />
      {!logged ? (
        <ThemeProvider theme={mdTheme}>
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[200]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "auto",
              overflow: "hidden",
            }}
          >
            <Container>
              <p className="font-spec font-semibold my-10 text-md lg:text-3xl tracking-wider">
                Informal Sector - General Enrollment
              </p>
              <div
                onClick={() => navigate(-1)}
                className="back"
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  cursor: "pointer",
                }}
              >
                <ArrowBackIosIcon fontSize={"small"} />
                <span>Back</span>
              </div>
              <Paper className="paper1">
                <form onSubmit={getGeneralValidation}>
                  <div className="textfield">
                    <TextField
                      className="box"
                      required
                      label="Payment Reference"
                      placeholder="Enter Payment Reference XXXX"
                      SelectProps={{
                        native: true,
                      }}
                      sx={{
                        width: "23ch",
                      }}
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                        maxLength: 13,
                      }}
                      onChange={(e) => setSelectedItem(e.target.value)}
                      value={selectedItem}
                    />
                  </div>
                  <div className="btn"></div>
                  <div className="app-btn1">
                    <Button
                      className="next"
                      type="submit"
                      disabled={isDisabled}
                      variant="contained"
                      color="success"
                    >
                      {buttonText}
                      <CheckCircleOutlineOutlinedIcon
                        style={{
                          fontSize: "20px",
                          marginBottom: "4px",
                          marginLeft: "4px",
                        }}
                      />
                    </Button>
                  </div>
                </form>
              </Paper>
            </Container>
          </Box>
        </ThemeProvider>
      ) : (
        <>
          <GeneralForm details={details} />
        </>
      )}
    </div>
  );
};

export default General;
