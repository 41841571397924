import Img from "../../src/images/PLASCHEMA.jpg";
import { useNavigate } from "react-router-dom";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import Avatar from "@mui/material/Avatar";

const Header = () => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-between bg-green-600 h-[16vh] ">
      <img
        src={Img}
        alt="logo"
        className="logo"
        onClick={(e) => {
          e.preventDefault();
          navigate("/");
        }}
      />
      <p className="font-pop font-semibold text-white text-[14px] lg:text-3xl">
        Plateau Health Enrollment Portal
      </p>

      <div className="flex flex-col items-center mt-[-1rem]">
        <Avatar
          style={{
            backgroundColor: "red",
            margin: "2rem 0.5rem",
            cursor: "pointer",
          }}
          title="Logout"
          onClick={(e) => {
            e.preventDefault();
            sessionStorage.removeItem("email_user");
            navigate("/");
          }}
        >
          <PowerSettingsNewOutlinedIcon />
        </Avatar>

        <button
          className="outline-none text-white mt-[-1.5rem]"
          onClick={() => {
            navigate("/search");
          }}
        >
          Search
        </button>
      </div>
    </div>
  );
};

export default Header;
