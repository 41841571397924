//import { TextField } from "@mui/material";
import Axios from "axios";
import React, { useState, useCallback } from "react";
import SearchItem from "./SearchItem";
import style from "styled-components";
import Paper from "@mui/material/Paper";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import SearchItem2 from "./SearchItem2";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

function Search() {
  const [search, setSearch] = useState([]);
  const [searchEnroll, setSearchEnroll] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleClick = () => {
    setChecked(!checked);
  };

  //debounce to regulate API call
  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 3000);
    };
  };

  //API call for nominal roll
  const handleChange = useCallback((event) => {
    setLoading(true);
    const { value } = event.target;

    const params = JSON.stringify({
      name: value,
    });

    Axios({
      method: "POST",
      url: "https://pshs3.herokuapp.com/search/enrollments/nominal",
      headers: {
        "Content-Type": "application/json",
      },
      data: params,
    })
      .then((response) => {
        if (value && value?.length > 0) {
          setSearch(response.data.data);
          setLoading(false);
        } else {
          setLoading(false);
          return [];
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  //optimised call for nominal roll
  const optimisedVersion = debounce(handleChange);

  const handleChange2 = useCallback((event) => {
    setLoading(true);
    const { value } = event.target;

    const params = JSON.stringify({
      phone_number: value,
      name: value,
    });

    Axios({
      method: "POST",
      url: "https://pshs3.herokuapp.com/search/enrollments/enrollments",
      headers: {
        "Content-Type": "application/json",
      },
      data: params,
    })
      .then((response) => {
        if (value && value?.length > 0) {
          setSearchEnroll(response.data.data);
          setLoading(false);
        } else {
          setLoading(false);
          return [];
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  //optimised call for enrollments
  const optimisedVersion2 = debounce(handleChange2);

  return (
    <Wrapper>
      <Paper component="form" className="paper" elevation={4}>
        <FormControlLabel
          control={
            <IOSSwitch sx={{ m: 1 }} checked={checked} onClick={handleClick} />
          }
          label="switch to search Nominal Roll"
        />

        {checked ? (
          <TextField
            className="box"
            label="Search Beneficiaries Nominal roll"
            type="text"
            autoComplete="off"
            variant="outlined"
            placeholder="search by name"
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ width: "40ch" }}
            onChange={optimisedVersion}
          />
        ) : (
          <TextField
            className="box"
            label="Search Beneficiaries Enrollment"
            type="text"
            autoComplete="off"
            variant="outlined"
            placeholder="search by name or phone number"
            sx={{ width: "40ch" }}
            onChange={optimisedVersion2}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      </Paper>
      <span>{loading ? <i>Searching...</i> : ""}</span>
      <div className="search-item">
        {checked
          ? search?.length > 0 && <SearchItem search={search} />
          : searchEnroll?.length > 0 && (
              <SearchItem2 searchEnroll={searchEnroll} />
            )}
      </div>
    </Wrapper>
  );
}

export default Search;

const Wrapper = style.div`
  display: flex;
  flex-direction: column;
  flex: 0 auto;
  align-content: center;
  background-color: #dfdfde;
 
  .paper {
    display: flex;
    flex-direction: column;
    flex: 0 auto;
    padding: 1.5rem;
    width: 500px;
    margin: 2rem auto;
  }
  .search-item {
    width: 80vw;
    margin: 2rem auto;
  }

  @media (max-width: 500px) {
     .paper{
      width: 100vw;
      
     }

   
    .search-item {
      width: 100vw;
      
    }
  }
`;
