const blood = [
    { key: "0", value: "O+" },
    { key: "1", value: "O-" },
    { key: "2", value: "A+" },
    { key: "3", value: "A-" },
    { key: "4", value: "B+" },
    { key: "5", value: "B-" },
    { key: "6", value: "AB+" },
    { key: "7", value: "AB-" },
    { key: "8", value: "Not Sure" },
  ];
  
  const emergency = [
    { key: "0", value: "Mother" },
    { key: "1", value: "Father" },
    { key: "2", value: "Sister" },
    { key: "3", value: "Brother" },
    { key: "4", value: "Uncle" },
    { key: "5", value: "Aunt" },
    { key: "6", value: "Wife" },
    { key: "7", value: "Husband" },
    { key: "8", value: "Son" },
    { key: "9", value: "Daughter"},
    { key: "10", value: "Others" },
  ];
  const marital = [
    { key: "0", value: "Married" },
    { key: "1", value: "Single" },
    { key: "2", value: "Divorced" },
    { key: "3", value: "Widowed" },
  ];
  const hmo = [
    { key: "0", value: "Ashmed" },
    { key: "1", value: "Infinite X2" },
    { key: "2", value: "Markfema" },
    { key: "3", value: "Precious Health" },
    { key: "4", value: "ProHealth" },
    { key: "5", value: "Regenix" },
    { key: "6", value: "First Care" },
    { key: "7", value: "Salus Trust" },
    { key: "8", value: "United Comprehensive" },
    { key: "9", value: "United Health" },
    { key: "10", value: "Well Health" },
    { key: "11", value: "Plaschema" },
  ];
  const idtype = [
    { key: "0", value: "National ID Card" },
    { key: "1", value: "International Passport" },
    { key: "2", value: "Voter's Card" },
    { key: "3", value: "Letter Of Attestation" },
    { key: "4", value: "Birth Certificate" },
    { key: "5", value: "Driver's License" },
  ];
  const genotype = [
    { key: "0", value: "AA" },
    { key: "1", value: "AS" },
    { key: "2", value: "SC" },
    { key: "3", value: "SS" },
    { key: "4", value: "Not Sure" },
  ];
  const category = [
    { key: "0", value: "5 years and below" },
    { key: "1", value: "Pregnant" },
    { key: "2", value: "Disability" },
    { key: "3", value: "65 and above" },
    { key: "4", value: "None of the Above" },
  ];
  const occupation = [
    { key: "0", value: "Public Servant" },
    { key: "1", value: "Civil Servant" },
    { key: "2", value: "Retired" },
    { key: "3", value: "Artisan" },
    { key: "4", value: "Unemployed" },
    { key: "5", value: "Pupil or Student" },
    { key: "6", value: "Child(Not in School)" },
    { key: "7", value: "Trader" },
    { key: "8", value: "Health Worker" },
    { key: "9", value: "Private Sector" },
    { key: "10", value: "Farmer" },
    { key: "11", value: "Others" },
  ];
 export  {occupation, category, genotype, idtype, hmo, emergency, marital, blood} 