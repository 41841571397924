import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Button from "@mui/material/Button";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import Axios from "axios";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { useNavigate } from "react-router-dom";
import StepLabel from "@mui/material/StepLabel";
import Preview from "./PreviewDependents";
import { ToastContainer, toast } from "react-toastify";
import { api } from "../../Api";
import "react-toastify/dist/ReactToastify.css";
import {
  category,
  genotype,
  idtype,
  occupation,
  emergency,
  marital,
  blood,
} from "../formData";

const myDate = new Date()
  .toISOString()
  .replace("-", "/")
  .split("T")[0]
  .replace("-", "/");

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": {
    borderColor: "green",
    borderWidth: 2,
  },
  "& input:invalid + fieldset": {
    borderColor: "red",
    borderWidth: 2,
  },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 5,
    padding: "4px !important",
  },
});
const mdTheme = createTheme();

const MAX_STEPS = 3;

function Form({ depEnroll}) {
 
  const [buttonText, setButtonText] = useState("Submit");
  const [formStep, setFormStep] = useState(0);

  const navigate = useNavigate();
  const [myLga, setMyLga] = useState([]);
  const [ward, setWard] = useState([]);
  const [selectedLga, setSelectedLga] = useState("");
  const [selectedWard, setSelectedWard] = useState("");
  const [homeAddress, setHomeAddress] = useState("");
  const [dob, setDob] = useState("");
  const [genotypeValue, setGenotype] = useState("");
  const [settlement, setSettlement] = useState("");
  const [idType, setIdType] = useState("");
  const [bloodGroup, setBloodgroup] = useState("");
  const [categoryValue, setCategory] = useState("");
  const [relationshipWithPrincipal, setRelationshipWithPrincipal] =
    useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [maritalStatus, setMarital] = useState("");
  const [dependentType, setDependentType] = useState("");
  const [dependentPhone, setDependentPhone] = useState("");
  const [passport, setPassport] = useState(null);
  const [idPhoto, setIdPhoto] = useState(null);
  const [nin, setNin] = useState("");
  const [nationality, setNationality] = useState("");
  const [occupationId, setOccupation] = useState("");
  const [dependentName, setDependentName] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (
      !selectedLga ||
      !selectedWard ||
      !dob ||
      !homeAddress ||
      !dependentName ||
      !dependentPhone ||
      !dependentType ||
      !genotypeValue ||
      !settlement ||
      !idType ||
      !bloodGroup ||
      !categoryValue ||
      !relationshipWithPrincipal ||
      !email ||
      !maritalStatus ||
      !gender ||
      passport === null ||
      idPhoto === null ||
      !nin ||
      !nationality ||
      !occupationId
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [
    selectedLga,
    selectedWard,
    dob,
    homeAddress,
    dependentName,
    dependentPhone,
    dependentType,
    genotypeValue,
    settlement,
    idType,
    bloodGroup,
    categoryValue,
    relationshipWithPrincipal,
    email,
    maritalStatus,
    gender,
    passport,
    idPhoto,
    nin,
    nationality,
    occupationId,
  ]);

  const getLocal = async () => {
    try {
      const fetchLocal = await Axios.get(`${api.baseURL}/lga/enrollment`);
      setMyLga(fetchLocal.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleWard = (e) => {
    const result = e.target.value;
    setSelectedLga(result);
    Axios.get(`${api.baseURL}/${result}/wards/enrollment`).then((response) => {
      setWard(response.data.data);
    });
  };

  useEffect(() => {
    getLocal();
  }, []);

  const completeFormStep = () => {
    setFormStep((cur) => cur + 1);
  };

  const renderButton = () => {
    if (formStep > 2) {
      return (
        <Button
          type="button"
          className="next"
          variant="outlined"
          onClick={(e) => {
            e.preventDefault();
            navigate("/");
          }}
        >
          Go Back Home
        </Button>
      );
    } else if (formStep === 2) {
      return (
        <Button
          className="next"
          type="submit"
          disabled={isDisabled}
          variant="contained"
          color="success"
        >
          {buttonText}
          <CheckCircleOutlineOutlinedIcon />
        </Button>
      );
    } else {
      return (
        <Button
          className="next"
          onClick={completeFormStep}
          type="button"
          variant="contained"
          color="success"
        >
          Next Step
          <NavigateNextRoundedIcon fontSize={"medium"} />
        </Button>
      );
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    setButtonText("Submitting Form...");
    var enrollData = new FormData();

    enrollData.append("passport", passport);
    enrollData.append("id_photo", idPhoto);
    enrollData.append("genotype", genotypeValue);
    enrollData.append("settlement_type", settlement);
    enrollData.append("id_type", idType);
    enrollData.append("lga", selectedLga);
    enrollData.append("principal_s_phone_number", depEnroll?.phone_number);
    enrollData.append("home_address", homeAddress);
    enrollData.append("blood_group", bloodGroup);
    enrollData.append("submission_id", depEnroll?.submission_id);
    enrollData.append("relationship_with_principal", relationshipWithPrincipal);
    enrollData.append("ward", selectedWard);
    enrollData.append("email", email);
    enrollData.append("nin_number", nin);
    enrollData.append("gender", gender);
    enrollData.append("facility_id", depEnroll?.facility?.id);
    enrollData.append("facility_name", depEnroll?.facility?.name);
    enrollData.append("hmo_id", depEnroll?.hmo?.id);
    enrollData.append("hmo_name", depEnroll?.hmo?.name);
    enrollData.append("category", categoryValue);
    enrollData.append("marital_status", maritalStatus);
    enrollData.append("nationality", nationality);
    enrollData.append("date_of_birth", dob);
    enrollData.append("subcategoryiid", depEnroll?.subcategoryitem?.id);
    enrollData.append("subcategoryitem", depEnroll?.subcategoryitem?.name);
    enrollData.append("occupation", occupationId);
    enrollData.append("dependant_s_phone_number", dependentPhone);
    enrollData.append("enrollment_id", depEnroll.enrollment_id);
    enrollData.append("type_of_dependent", dependentType);
    enrollData.append("name", dependentName);

   
    Axios({
      method: "POST",
      url:`${api.baseURL}/dependents/details`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: enrollData,
    })
      .then((response) => {
        console.log(response);
        toast.success("Dependent Enrolled Successfully!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 8000,
          hideProgressBar: true,
        });
        setButtonText("Submit");
        completeFormStep();
      })
      .catch(function (error) {
        console.log(error);
        setButtonText("Submit");
        toast.error("Form submit error. Try again!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 8000,
          hideProgressBar: true,  
        });
      });
  };

  const goToPreviousStep = () => {
    setFormStep((cur) => cur - 1);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <ToastContainer />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[200]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "auto",
          overflow: "hidden",
        }}
      >
        <Toolbar />

        <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
          <p className="font-spec font-semibold mb-10 text-md lg:text-3xl tracking-wider">
            Formal Sector Beneficiaries- Dependents Enrollment Form
          </p>
          {formStep < MAX_STEPS && (
            <div>
              <div className="stepper">
                <Stepper activeStep={formStep}>
                  <Step>
                    <StepLabel>Principal Information</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Dependent Information</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Others</StepLabel>
                  </Step>
                </Stepper>
              </div>
              <div className="steps">
                {formStep > 0 && (
                  <ArrowBackIosIcon
                    onClick={goToPreviousStep}
                    fontSize={"10px"}
                  />
                )}

                <p>
                  Step {formStep + 1} of {MAX_STEPS}
                </p>
              </div>
            </div>
          )}

          {/* form */}

          <form onSubmit={submitForm}>
            <Paper className="paper">
              {formStep >= 0 && (
                <div
                  style={
                    formStep === 0 ? { display: "block" } : { display: "none" }
                  }
                >
                  <div className="textfield">
                    <TextField
                      className="box"
                      name="mda"
                      label="Subcategory item"
                      SelectProps={{
                        native: true,
                      }}
                      sx={{
                        width: "23ch",
                      }}
                      value={depEnroll?.subcategoryitem?.name || "No data"}
                    />

                    <ValidationTextField
                      className="box"
                      label=" Principal's Submission ID (PSN)"
                      name="submission_id"
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                      placeholder="Enter enrollee Submission Id / PSN"
                      id="validation-outlined-input"
                      value={depEnroll?.submission_id || "No data"}
                    />

                    <TextField
                      className="box"
                      label="Principal Name"
                      name="prin_name"
                      variant="outlined"
                      id="outlined-input"
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{
                        width: "23ch",
                      }}
                      value={depEnroll?.name || "No data"}
                    />
                  </div>
                  <div className="textfield">
                    <ValidationTextField
                      className="box"
                      label="Enrollment ID"
                      InputProps={{
                        readOnly: true,
                      }}
                      name="enrollment_id"
                      variant="outlined"
                      placeholder="Enter enrollee Enrollment Id"
                      id="validation-outlined-input"
                      value={depEnroll?.enrollment_id || "No data"}
                    />

                    <TextField
                      className="box"
                      name="hmo"
                      label="HMO"
                      InputProps={{
                        readOnly: true,
                      }}
                      SelectProps={{
                        native: true,
                      }}
                      sx={{
                        width: "23ch",
                      }}
                      value={depEnroll?.hmo?.name || "No data"}
                    />

                    <ValidationTextField
                      className="box"
                      label="Principal's Phone Number"
                      type="text"
                      InputProps={{
                        readOnly: true,
                      }}
                      name="principal_s_phone_number"
                      variant="outlined"
                      placeholder="Enter Principal's Phone Number"
                      value={depEnroll?.phone_number || "No data"}
                    />
                  </div>
                  <div className="textfield">
                    <TextField
                      className="box"
                      name="Facility"
                      label="Facility"
                      InputProps={{
                        readOnly: true,
                      }}
                      SelectProps={{
                        native: true,
                      }}
                      sx={{
                        width: "23ch",
                      }}
                      value={depEnroll?.facility?.name || "No data"}
                    />
                    <TextField
                      className="box"
                      name="Facility_id"
                      label="Facility Id"
                      InputProps={{
                        readOnly: true,
                      }}
                      SelectProps={{
                        native: true,
                      }}
                      sx={{
                        width: "23ch",
                      }}
                      value={depEnroll?.facility?.id || "No data"}
                    />

                    <TextField
                      className="box"
                      name="status"
                      label="Status"
                      InputProps={{
                        readOnly: true,
                      }}
                      SelectProps={{
                        native: true,
                      }}
                      sx={{
                        width: "23ch",
                      }}
                      value={depEnroll?.status || "No data"}
                    />
                  </div>
                </div>
              )}
              {formStep >= 1 && (
                <div
                  style={
                    formStep === 1 ? { display: "block" } : { display: "none" }
                  }
                >
                  <div className="textfield">
                    <TextField
                      className="box"
                      select
                      name="type_of_dependent"
                      label="Type of Dependents"
                      required
                      SelectProps={{
                        native: true,
                      }}
                      sx={{
                        width: "23ch",
                      }}
                      value={dependentType}
                      onChange={(e) => setDependentType(e.target.value)}
                    >
                      <option>Select Type of Dependents</option>
                      <option value="primary">Primary</option>
                      <option value="extra dependent">Extra Dependent</option>
                    </TextField>
                    <ValidationTextField
                      className="box"
                      required
                      label="Dependent's Name"
                      name="Dependent's_name"
                      variant="outlined"
                      placeholder="Enter Dependent's Name"
                      value={dependentName}
                      onChange={(e) => setDependentName(e.target.value)}
                    />
                    <ValidationTextField
                      className="box"
                      label="Dependent's Phone Number"
                      type="text"
                      required
                      name="dependent_s_phone_number"
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                        maxLength: 11,
                      }}
                      variant="outlined"
                      placeholder="Enter Dependent's Phone Number"
                      id="validation-outlined-input"
                      value={dependentPhone}
                      onChange={(e) => setDependentPhone(e.target.value)}
                    />
                  </div>
                  <div className="textfield">
                    <ValidationTextField
                      className="box"
                      required
                      label="Home Address"
                      name="home_address"
                      variant="outlined"
                      placeholder="Enter Home Address"
                      value={homeAddress}
                      onChange={(e) => setHomeAddress(e.target.value)}
                    />
                    <TextField
                      className="box"
                      select
                      label="Relationship With Principal"
                      name="relationship_with_principal"
                      required
                      SelectProps={{
                        native: true,
                      }}
                      sx={{
                        width: "23ch",
                      }}
                      value={relationshipWithPrincipal}
                      onChange={(e) =>
                        setRelationshipWithPrincipal(e.target.value)
                      }
                    >
                      <option>Please Select</option>
                      {emergency.map((r) => (
                        <option value={r.value} key={r.key}>
                          {r.value}
                        </option>
                      ))}
                    </TextField>

                    <TextField
                      className="box"
                      select
                      label="ID Type"
                      name="id_type"
                      required
                      SelectProps={{
                        native: true,
                      }}
                      sx={{
                        width: "23ch",
                      }}
                      value={idType}
                      onChange={(e) => setIdType(e.target.value)}
                    >
                      <option>Please Select your ID type</option>
                      {idtype.map((k) => (
                        <option value={k.value} key={k.key}>
                          {k.value}
                        </option>
                      ))}
                    </TextField>
                  </div>

                  <div className="textfield">
                    <ValidationTextField
                      className="box"
                      type="date"
                      required
                      label="Date of Birth"
                      name="date_of_birth"
                      variant="outlined"
                      sx={{
                        width: "23ch",
                      }}
                      value={dob}
                      onChange={(e) => setDob(e.target.value)}
                    />
                    <ValidationTextField
                      className="box"
                      label="NIN"
                      type="text"
                      name="nin_number"
                      variant="outlined"
                      placeholder="Enter NIN number"
                      value={nin}
                      onChange={(e) => setNin(e.target.value)}
                    />
                    <TextField
                      className="box"
                      select
                      name="gender"
                      required
                      SelectProps={{
                        native: true,
                      }}
                      sx={{
                        width: "23ch",
                      }}
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                    >
                      <option>Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </TextField>
                  </div>
                  <div className="textfield">
                    <TextField
                      className="box"
                      select
                      label="Blood Group"
                      name="blood_group"
                      required
                      SelectProps={{
                        native: true,
                      }}
                      sx={{
                        width: "23ch",
                      }}
                      value={bloodGroup}
                      onChange={(e) => setBloodgroup(e.target.value)}
                    >
                      <option>Select your Blood Group</option>
                      {blood.map((item) => (
                        <option value={item.value} key={item.key}>
                          {item.value}
                        </option>
                      ))}
                    </TextField>
                    <TextField
                      className="box"
                      select
                      label="Genotype"
                      name="genotype"
                      required
                      SelectProps={{
                        native: true,
                      }}
                      sx={{
                        width: "23ch",
                      }}
                      value={genotypeValue}
                      onChange={(e) => setGenotype(e.target.value)}
                    >
                      <option>Select your Genotype</option>
                      {genotype.map((g) => (
                        <option value={g.value} key={g.key}>
                          {g.value}
                        </option>
                      ))}
                    </TextField>
                    <TextField
                      className="box"
                      select
                      label="Category"
                      name="category"
                      required
                      SelectProps={{
                        native: true,
                      }}
                      sx={{
                        width: "23ch",
                      }}
                      value={categoryValue}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <option>Select your Category</option>
                      {category.map((c) => (
                        <option value={c.value} key={c.key}>
                          {c.value}
                        </option>
                      ))}
                    </TextField>
                  </div>
                </div>
              )}

              {formStep >= 2 && (
                <div
                  style={
                    formStep === 2 ? { display: "block" } : { display: "none" }
                  }
                >
                  <div className="textfield">
                    <TextField
                      className="box"
                      required
                      name="id_photo"
                      accept="image/*"
                      id="contained-button-file"
                      type="file"
                      label="Upload ID Photo"
                      sx={{
                        width: "24ch",
                      }}
                      onChange={(e) => setIdPhoto(e.target.files[0])}
                    />

                    <TextField
                      className="box"
                      required
                      name="passport"
                      accept="image/*"
                      id="contained-button-file"
                      sx={{
                        width: "24ch",
                      }}
                      type="file"
                      label="Upload Passport"
                      onChange={(e) => setPassport(e.target.files[0])}
                    />
                    <ValidationTextField
                      className="box"
                      label="Email Address"
                      type="email"
                      name="email"
                      variant="outlined"
                      placeholder="Enter your email address"
                      id="validation-outlined-input"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="textfield">
                    <TextField
                      className="box"
                      select
                      label="Marital Status"
                      name="marital_status"
                      required
                      SelectProps={{
                        native: true,
                      }}
                      sx={{
                        width: "23ch",
                      }}
                      value={maritalStatus}
                      onChange={(e) => setMarital(e.target.value)}
                    >
                      <option>Please Select</option>
                      {marital.map((i) => (
                        <option value={i.value} key={i.key}>
                          {i.value}
                        </option>
                      ))}
                    </TextField>
                    <TextField
                      className="box"
                      name="lga"
                      select
                      required
                      value={selectedLga}
                      onChange={handleWard}
                      SelectProps={{
                        native: true,
                      }}
                      sx={{
                        width: "23ch",
                      }}
                    >
                      <option value="">Select LGA </option>
                      {myLga?.map((curr) => (
                        <option key={curr}>{curr}</option>
                      ))}
                    </TextField>
                    <TextField
                      className="box"
                      name="ward"
                      select
                      required
                      value={selectedWard}
                      onChange={(e) => setSelectedWard(e.target.value)}
                      SelectProps={{
                        native: true,
                      }}
                      sx={{
                        width: "23ch",
                      }}
                    >
                      <option value="">Select Ward</option>
                      {ward?.map((dep) => (
                        <option key={dep}>{dep}</option>
                      ))}
                    </TextField>
                  </div>
                  <div className="textfield">
                    <TextField
                      className="box"
                      select
                      label="Occupation"
                      name="Occupation"
                      required
                      SelectProps={{
                        native: true,
                      }}
                      sx={{
                        width: "23ch",
                      }}
                      value={occupationId}
                      onChange={(e) => setOccupation(e.target.value)}
                    >
                      <option>Select Occupation</option>
                      {occupation.map((res) => (
                        <option value={res.value} key={res.key}>
                          {res.value}
                        </option>
                      ))}
                    </TextField>
                    <TextField
                      className="box"
                      select
                      label="Nationality"
                      name="nationality"
                      required
                      SelectProps={{
                        native: true,
                      }}
                      sx={{
                        width: "23ch",
                      }}
                      value={nationality}
                      onChange={(e) => setNationality(e.target.value)}
                    >
                      <option>Select your Nationality</option>
                      <option value="nigerian">Nigerian</option>
                      <option value="non nigerian">Non-Nigerian</option>
                    </TextField>
                    <TextField
                      className="box"
                      select
                      name="settlement_type"
                      label="Settlement Type"
                      required
                      SelectProps={{
                        native: true,
                      }}
                      sx={{
                        width: "23ch",
                      }}
                      value={settlement}
                      onChange={(e) => setSettlement(e.target.value)}
                    >
                      <option>Select Settlement Type</option>
                      <option value="rural">Rural</option>
                      <option value="urban">Urban</option>
                      <option value="idp camp">IDP Camp</option>
                    </TextField>
                  </div>
                </div>
              )}
              {formStep === 3 && (
                <Preview
                  depEnroll={depEnroll}
                  passport={passport}
                  gender={gender}
                  dependentName={dependentName}
                  myDate={myDate}
                  bloodGroup={bloodGroup}
                />
              )}
            </Paper>
            <div className="btn">{renderButton()}</div>
          </form>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default Form;
