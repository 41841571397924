import React, { useState, useEffect, useCallback } from "react";
import Form from "./FormDependents";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { api } from "../../Api";
import "react-toastify/dist/ReactToastify.css";

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": {
    borderColor: "green",
    borderWidth: 2,
  },
  "& input:invalid + fieldset": {
    borderColor: "red",
    borderWidth: 2,
  },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 5,
    padding: "4px !important",
  },
});
const mdTheme = createTheme();

const MainDependent = () => {
  const [isLogged, setLogged] = useState(false);
  const navigate = useNavigate();

  const [subCategory, setSubCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [subCategoryItem, setSubCategoryItem] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");
  const [id, setId] = useState("");
  const [depEnroll, setDepEnroll] = useState("");
  const [buttonText, setButtonText] = useState("Validate");
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (selectedItem === "" || selectedSubCategory === "" || id === "") {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [selectedItem, selectedSubCategory, id]);

  const handleSubCategory = useCallback(async () => {
    try {
      const fetch = await Axios.post(`${api.baseURL}/category/Formal`);
      const result = fetch.data.data;
      setSubCategory(result);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleSubCategoryItem = useCallback(async (e) => {
    const impt = e.target.value;
    setSelectedSubCategory(impt);
    try {
      const fetchItem = await Axios.post(`${api.baseURL}//Formal/${impt}`);
      const subItem = fetchItem.data.data;
      setSubCategoryItem(subItem);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    handleSubCategory();
  }, [handleSubCategory]);

  //validation
  const getDetails = async (e) => {
    e.preventDefault();
    setButtonText("Validating...");

    const params = JSON.stringify({
      submission_id: id,
      subcategoryiid: selectedItem,
    });

    const link = `${api.baseURL}/validate/principal`;

    Axios.post(link, params, {
      headers: {
        "content-type": "application/json",
      },
    })
      .then(function (response) {
        if (
          response.status === 200 &&
          response.data.status === true &&
          response.data.data.number_dep < 5 &&
          response.data.data.status === "Merged"
        ) {
          setLogged(true);
          setButtonText("Validate");
          setIsDisabled(true);
          setSelectedItem("");
          setSelectedSubCategory("");
          setId("");
          setDepEnroll(response.data.data);
          toast.success("Validation Successfull!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            hideProgressBar: true,
          });
        } else if (
          response.status === 200 &&
          response.data.status === true &&
          response.data.data.number_dep >= 5 &&
          response.data.data.status === "Merged"
        ) {
          setLogged(false);
          setButtonText("Validate");
          setIsDisabled(true);
          setSelectedItem("");
          setSelectedSubCategory("");
          setId("");
          toast.info(
            "You've reached the maximum number of Dependents. If you wish to add extra dependents, please Contact PLASCHEMA on 0700-PLATEAU!",
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 12000,
              hideProgressBar: true,
            }
          );
        } else if (response.status === 200 && response.data.status === false) {
          setLogged(false);
          setButtonText("Validate");
          setIsDisabled(true);
          setSelectedItem("");
          setSelectedSubCategory("");
          setId("");
          toast.error(
            "Principal record not found. Please Contact PLASCHEMA on 0700-PLATEAU",
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 5000,
              hideProgressBar: true,
            }
          );
        } else {
          setLogged(false);
          setButtonText("Validate");
          setIsDisabled(true);
          setSelectedItem("");
          setSelectedSubCategory("");
          setId("");
          toast.error("Try again later!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
            hideProgressBar: true,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        setButtonText("Validate");
        setIsDisabled(true);
        setSelectedItem("");
        setSelectedSubCategory("");
        setId("");
      });
  };

  return (
    <div>
      <ToastContainer />
      {!isLogged ? (
        <ThemeProvider theme={mdTheme}>
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[200]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "auto",
              overflow: "hidden",
            }}
          >
            <Container>
              <p className="font-spec font-semibold my-10 text-md lg:text-3xl tracking-wider">
                Enrollment - Formal Sector Dependent Enrollment
              </p>
              <div
                onClick={() => navigate(-1)}
                className="back"
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  cursor: "pointer",
                }}
              >
                <ArrowBackIosIcon fontSize={"small"} />
                <span>Back</span>
              </div>
              <Paper className="paper1">
                <form onSubmit={getDetails}>
                  <div className="textfield">
                    <TextField
                      className="box"
                      select
                      SelectProps={{
                        native: true,
                      }}
                      sx={{
                        width: "23ch",
                      }}
                      onChange={handleSubCategoryItem}
                      value={selectedSubCategory}
                    >
                      <option value="">Select Subcategory</option>
                      {subCategory?.map((options) => (
                        <option key={options} value={options}>
                          {options}
                        </option>
                      ))}
                    </TextField>
                    <TextField
                      className="box"
                      select
                      SelectProps={{
                        native: true,
                      }}
                      sx={{
                        width: "23ch",
                      }}
                      onChange={(e) => setSelectedItem(e.target.value)}
                      value={selectedItem}
                    >
                      <option value="">Select Subcategory item</option>
                      {subCategoryItem?.map((opt) => (
                        <option key={opt.id} value={opt.id}>
                          {opt.name}
                        </option>
                      ))}
                    </TextField>

                    <ValidationTextField
                      className="box"
                      label="Submission ID (or PSN)"
                      name="id"
                      variant="outlined"
                      placeholder="Enter enrollee submission id"
                      onChange={(e) => setId(e.target.value)}
                      value={id}
                      id="validation-outlined-input"
                    />
                  </div>
                  <div className="btn">
                    <div className="app-btn1">
                      <Button
                        className="next"
                        type="submit"
                        disabled={isDisabled}
                        variant="contained"
                        color="success"
                      >
                        <CheckCircleOutlineOutlinedIcon
                          style={{
                            fontSize: "20px",
                            marginBottom: "4px",
                            marginLeft: "4px",
                          }}
                        />
                        {buttonText}
                      </Button>
                    </div>
                  </div>
                </form>
              </Paper>
            </Container>
          </Box>
        </ThemeProvider>
      ) : (
        <>
          <Form depEnroll={depEnroll} />
        </>
      )}
    </div>
  );
};

export default MainDependent;
